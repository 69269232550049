@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
    font-family: "Kanit", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    background-color: #ebedf0;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.rotating {
    animation: rotate 2s linear infinite;
}